import React from 'react'
import _ from 'lodash'

import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { renderCheckService } from 'ui/components/ContainerCommonPanels/utilsFunctions'

const ExpansionPanelCadastralExtension = ({ serviceInfos }) => {
  const theme = useTheme()

  const renderService = (service) => {
    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    if (!_.get(service, 'data.consists')) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    return <strong style={{ color: theme.palette.primary.red }}>Sim</strong>
  }

  const renderListServices = () => {
    const serviceIds = [
      'sanctions_list',
      'onu_sanction_lists',
      'uk_sanction_list',
      'fr_sanction_list',
      'eu_sanction_list',
      'switzerland_entities_sanction_list',
      'us_nonproliferation_sanction_list',
      'world_bank_ineligible_sanction_list',
    ]

    return _.map(serviceIds, (serviceId) => {
      const service = _.find(serviceInfos, { service_id: serviceId })
      if (_.isEmpty(service)) return null

      let functionRender = null
      switch (serviceId) {
        default:
          functionRender = renderService
          break
      }

      return (
        <Grid key={serviceId} container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{service.name_short}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {functionRender(service)}
          </Grid>
        </Grid>
      )
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 16 }}>
      {renderListServices()}
    </div>
  )
}

export default ExpansionPanelCadastralExtension
